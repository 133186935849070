import React, { Component } from "react";
//import { Link } from "react-router-dom";
class Navbar extends Component {
  render() {
    return (
      <nav role="navigation">
        <div className="nav-wrapper container">
          <img src="/img/tooly-logo-white.svg" className="brand-img" alt="Logo" />
          <a id="logo-container" href="/" className="brand-logo">Tooly</a>
    
          <ul id="nav-mobile" className="sidenav">
            <li><a href="/">&nbsp;</a></li>
          </ul>
          <a href="/" data-target="nav-mobile" className="sidenav-trigger"><img src="/img/tooly-logo.svg" className="menu-img" alt="menu" /></a>
        </div>
      </nav>
    );
  }
}

/*

          <ul className="right hide-on-med-and-down">
            <li><a href="#">Navbar Link</a></li>
          </ul>
*/

export default Navbar;