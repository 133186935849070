import React, { Component } from "react";
import { Link } from "react-router-dom";

class noAPIErrorPage extends Component {
  render() {
    return (
      <div className="container">
        <div style={{ marginTop: "4rem" }} className="row">
          <div className="col s8 offset-s2">
            <img src="/img/dead-process.png" style={{width:"10em"}} alt="Server Error" />
            <h4>Sorry, it's not you. It's us.</h4>
            <p>We are experiencing network issues connecting to our API.
             Please come back later or contact <Link to="mailto:support@contentwise.info">System Administrator</Link></p>
            <p className="grey-text text-darken-1">
              or continue browsing <Link to="/">here</Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default noAPIErrorPage;
