import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING
} from "./types";

const urls = require("../config");

function handleError(err) {
  if (err.response === undefined) {
    return {
      type: GET_ERRORS,
      payload: {
        passedfromapi: err.message
      }
    }
  } else {
    return {
      type: GET_ERRORS,
      payload: err.response.data
    }
  }
}

// Register User
export const registerUser = (userData, history) => dispatch => {
  axios
    .post(urls.backendUrl + "/api/users/register", userData)
    .then(res => history.push("/check-your-mail")) // re-direct to login on successful register
    .catch(err => {
      const errPayload = handleError(err);
      if (errPayload.payload.passedfromapi) {
        history.push('/500');
      } else {
        dispatch(errPayload);
      }
    });
};

// Login - get user token
export const loginUser = (userData, history) => dispatch => {
  axios
    .post(urls.backendUrl + "/api/users/login", userData)
    .then(res => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch(err => {
      const errPayload = handleError(err);
      if (errPayload.payload.passedfromapi) {
        history.push('/500');
      } else if (errPayload.payload.noconfirmation) {
        history.push('/check-your-mail');
      } else {
        dispatch(errPayload);
      }
    });
};

// Login - get user token
export const confirmUser = (userData, history) => dispatch => {
  axios
    .post(urls.backendUrl + "/api/users/confirm", userData)
    .then(history.push("/login")) // re-direct to login on successful register
    .catch(err => {
      const errPayload = handleError(err);
      if (errPayload.payload.passedfromapi) {
        history.push('/500');
      } else {
        dispatch(errPayload);
      }
    });
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
