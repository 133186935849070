import React, { Component } from "react";
import { Link } from "react-router-dom";

class CheckMailbox extends Component {
  render() {
    return (
      <div className="container">
        <div style={{ marginTop: "4rem" }} className="row">
          <div className="col s8 offset-s2">
            <h4>Please check your mailbox and verify you have access to your email address.</h4>
            <p className="grey-text text-darken-1">
              Then return and <Link to="/login">Log in</Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default CheckMailbox;
