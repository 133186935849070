import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { confirmUser } from "../../actions/authActions";

class Confirm extends Component {
/*  constructor() {
    super();
    this.state = {
      cid: "",
      errors: {}
    };
  }
*/
  state = {
    cid: "",
    errors: {}
  };

  static getDerivedStateFromProps(props, state) {
    if (props.errors) {
      return {
        cid: state.cid,
        errors: props.errors
      };
    }
    return null;
  }

  componentDidMount() {
    const cid = this.props.match.params.cid;
    if (cid) {
      this.setState({ "cid": cid });
      this.props.confirmUser({"cid": cid}, this.props.history);
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const userData = {
      cid: this.state.cid
    };

    this.props.confirmUser(userData); // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
  };

  render() {
    if (this.state.cid && this.state.cid !== "") {
      this.props.confirmUser({"cid": this.state.cid});
    }

    const { errors } = this.state;

    return (
      <div className="container">
        <div style={{ marginTop: "4rem" }} className="row">
        <span className="red-text">
          {errors.password}
          {errors.passwordincorrect}
        </span>
          <div className="col s8 offset-s2">
            <h3>Thanks for confirming your email</h3>
          </div>
        </div>
      </div>
    );
  }
}

Confirm.propTypes = {
  confirmUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { confirmUser }
)(Confirm);
